import { Component, OnInit } from '@angular/core';
import Phaser from 'phaser';
import { Game } from './scenes/Game';
import { MainMenu } from './scenes/MainMenu';

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit {
    phaserGame: Phaser.Game;
    config: Phaser.Types.Core.GameConfig;
    constructor() {
        this.config = {
            type: Phaser.AUTO,
            height: '100%',
            width: '100%',
            scene: [MainMenu, Game],
            parent: 'gameContainer',
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { x: 0, y: 100 },
                },
            },
        };
    }
    ngOnInit() {
        this.phaserGame = new Phaser.Game(this.config);
    }
}
