import Phaser from 'phaser';
import { environment } from '../../../environments/environment';
import * as jose from 'jose';

export class Game extends Phaser.Scene {
    private MAX_GAME_TIME = 20000;
    private RED_GREEN_TOGGLE_TIME = 2000;
    private RED_WAIT_TIME = 300;
    private GREEN_COLOR = 0x2eff00;
    private RED_COLOR = 0xff0000;
    private player: Phaser.GameObjects.Sprite;
    private playerScale = 1;
    private graphics: Phaser.GameObjects.Graphics;
    private seedRect: Phaser.Geom.Rectangle;
    private rectangles = [] as Phaser.Geom.Rectangle[];
    private isRedOn = false;
    private timedEvent: Phaser.Time.TimerEvent;
    private countDownText: Phaser.GameObjects.Text;
    private isTapping = false;
    private scoreText: Phaser.GameObjects.Text;
    private score = 0;
    private redToggleInterval: NodeJS.Timeout;

    constructor() {
        super({ key: 'Game' });
    }

    public preload() {
        this.load.setBaseURL('assets/');
        this.load.image('player-1', 'player/01.png');
        this.load.image('player-2', 'player/02.png');
        this.load.image('player-3', 'player/03.png');
        this.load.image('player-4', 'player/04.png');
        for (let i = 0; i < 17; i++) {
            this.load.image(`blast-${i}`, `player/blast-${i}.png`);
        }
    }

    public create() {
        const width = this.scale.width;
        const height = this.scale.height;

        // background
        this.graphics = this.add.graphics({
            lineStyle: { color: 0x3abc12 },
            fillStyle: { color: 0x0000aa },
        });
        this.graphics.postFX.addTiltShift(0.2);
        this.graphics.postFX.addShine(0.5, 0.5, 4);
        this.graphics.postFX.addBlur(1, 0.025, 0, 10);

        const baseWidth = width / 8;
        const baseHeight = height / 8;
        this.seedRect = new Phaser.Geom.Rectangle(
            width / 2 - baseWidth / 2,
            height / 2 - (baseHeight / 2 + 50),
            baseWidth,
            baseHeight
        );

        // player
        this.player = this.add.sprite(width / 2, height - 100, 'player-1');
        this.anims.create({
            key: 'adam-walk',
            frames: [
                { key: 'player-1' },
                { key: 'player-2' },
                { key: 'player-3' },
                { key: 'player-4' },
            ],
            repeat: -1,
            frameRate: 8,
        });
        this.anims.create({
            key: 'blast',
            frames: [
                { key: 'blast-0' },
                { key: 'blast-1' },
                { key: 'blast-2' },
                { key: 'blast-3' },
                { key: 'blast-4' },
                { key: 'blast-5' },
                { key: 'blast-6' },
                { key: 'blast-7' },
                { key: 'blast-8' },
                { key: 'blast-9' },
                { key: 'blast-10' },
                { key: 'blast-11' },
                { key: 'blast-12' },
                { key: 'blast-13' },
                { key: 'blast-14' },
                { key: 'blast-15' },
                { key: 'blast-16' },
            ],
            frameRate: 16,
        });

        // timer
        this.redToggleInterval = setInterval(() => {
            this.isRedOn = true;
            this.time.delayedCall(
                this.RED_WAIT_TIME,
                () => (this.isRedOn = false)
            );
        }, this.RED_GREEN_TOGGLE_TIME);

        this.timedEvent = this.time.delayedCall(this.MAX_GAME_TIME, () =>
            this.gameOver('Time is up')
        );
        this.countDownText = this.add
            .text(width / 2, 60, '')
            .setStyle({
                fontSize: 58,
                fontStyle: 'bold',
                fontFamily: 'Silkscreen',
            })
            .setOrigin(0.5);

        this.scoreText = this.add
            .text(width / 2, height - 25, 'Score: 0')
            .setStyle({
                fontSize: 32,
                fontStyle: 'bold',
                fontFamily: 'Silkscreen',
            })
            .setOrigin(0.5);

        // input
        this.input.on('pointerdown', () => {
            this.isTapping = true;

            if (!this.player.anims.isPlaying)
                this.player.anims.play('adam-walk');
        });

        this.input.on('pointerup', () => {
            this.isTapping = false;
            this.player.anims.stop();
        });
    }

    override update() {
        const width = this.scale.width;

        // countdown & score
        const timeRemains =
            (Math.floor(
                this.MAX_GAME_TIME - this.timedEvent.getProgress() * 1000
            ) -
                (this.MAX_GAME_TIME - 1000)) /
            100;
        this.countDownText.setText(timeRemains.toFixed(2));
        if (timeRemains < 5) {
            this.countDownText.setColor('#ff5900');
            this.countDownText.postFX.addShine(2);
        }

        this.scoreText.setText(`Score: ${this.score}`);

        // background
        const clone = Phaser.Geom.Rectangle.Clone(this.seedRect);
        this.rectangles.push(clone);
        this.graphics.clear();

        for (let i = 0; i < this.rectangles.length; i++) {
            const rect = this.rectangles[i];

            Phaser.Geom.Rectangle.Inflate(
                rect,
                rect.width * 0.05,
                rect.height * 0.05
            );

            if (rect.width > width + 200) {
                this.rectangles.splice(i--, 1);
            } else {
                const color = this.isRedOn ? this.RED_COLOR : this.GREEN_COLOR;
                this.graphics.lineStyle(1, color);
                this.graphics.strokeRectShape(rect);
            }
        }

        // player
        if (this.isTapping) {
            this.player.setScale(this.playerScale);

            if (this.isRedOn) {
                this.gameOver('You moved in red');
            } else {
                this.playerScale -= 0.00075;
                this.player.setPosition(this.player.x, this.player.y - 0.35);
                this.score += 10;
            }
        } else {
        }
    }

    private async sendTokens(
        amount: number,
        userId: number,
        address: string
    ): Promise<void> {
        try {
            const response = await fetch(
                `${environment.backendApiUrl}/crypto-verify/${userId}`
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const secret = await response.json();

            // Add the server data to the display
            // this.add.text(100, 200, `Server Data: ${this.serverData}`, { fontSize: '32px', fill: '#fff' });
            if (
                typeof secret?.nonce === 'undefined' ||
                secret.nonce?.length < 3
            ) {
                return;
            }

            const secretKey = new TextEncoder().encode(secret.nonce);

            const signed = await new jose.SignJWT({
                userid: userId,
                address: address,
                tokens: amount,
            })
                .setProtectedHeader({ alg: 'HS256' })
                .setIssuedAt()
                .setExpirationTime('1m')
                .sign(secretKey);

            const hashResponse = await fetch(
                `${environment.backendApiUrl}/send-tokens/${signed}`
            );
            if (!hashResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const hash = await hashResponse.json();
            if (typeof hash?.hash !== 'undefined' || hash.hash?.length < 3) {
                localStorage.setItem('pending', hash.hash);
                setTimeout(() => location.reload(), 2000);
            }
        } catch (error) {
            console.error('Error fetching server data:', error);
            setTimeout(() => location.reload(), 2000);
            return;
        }
    }

    gameOver(reason: string) {
        this.input.removeAllListeners();
        this.isTapping = false;
        this.isRedOn = true;
        clearInterval(this.redToggleInterval);

        this.player.anims.play('blast');
        const highScore = localStorage.getItem('highScore');
        if (+highScore < this.score) {
            localStorage.setItem('highScore', this.score.toString());
        }

        this.add
            .text(this.scale.width * 0.5, this.scale.height * 0.5, reason, {
                fontSize: 42,
                fontFamily: 'Silkscreen',
                backgroundColor: '#000000',
                wordWrap: { width: this.scale.width, useAdvancedWrap: true },
                align: 'center',
            })
            .setOrigin(0.5);

        const urlParams = new URLSearchParams(window.location.search);
        const address = urlParams.get('address') || '';
        const userId = parseInt(urlParams.get('userid') || '0');
        const limit = environment.scoreLimit;
        const multiplier = environment.scoreMultiplier;
        const hasParams = userId >= 0 && address.length > 2;
        if (hasParams && limit < this.score) {
            this.sendTokens(this.score * multiplier, userId, address).then();
        } else {
            setTimeout(() => location.reload(), 2000);
        }
    }
}
