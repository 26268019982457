import Phaser from 'phaser';
import { Game } from './Game';
import { environment } from '../../../environments/environment';

export class MainMenu extends Phaser.Scene {
    private graphics: Phaser.GameObjects.Graphics;
    private seedRect: Phaser.Geom.Rectangle;
    private rectangles = [] as any[];
    private highScore = 0;
    private scoreText: Phaser.GameObjects.Text;
    private baseWidth: number;
    private baseHeight: number;
    private tokenText: Phaser.GameObjects.Text;
    private tokenIcon: Phaser.GameObjects.Image;
    private pendingHash: string;
    private address: string;

    constructor() {
        super({ key: 'MainMenu' });
    }

    preload() {
        this.load.plugin(
            'rexbbcodetextplugin',
            'https://raw.githubusercontent.com/rexrainbow/phaser3-rex-notes/master/dist/rexbbcodetextplugin.min.js',
            true
        );
        this.load.image('tgs_token', 'assets/tgs.png');
        this.load.image('reload', 'assets/reload.webp');
    }

    private async fetchBalance(): Promise<void> {
        try {
            this.tokenText?.destroy();
            this.tokenIcon?.destroy();
            if (this.pendingHash && (await this.checkHash())) {
                this.pendingHash = '';
                localStorage.removeItem('pending');
            }
            const response = await fetch(
                `${environment.backendApiUrl}/token-balance/${this.address}`
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            // Add the server data to the display
            // this.add.text(100, 200, `Server Data: ${this.serverData}`, { fontSize: '32px', fill: '#fff' });
            if (typeof data.balance !== 'undefined' && data.balance.length) {
                const width = this.scale.width;
                const height = this.scale.height;
                const coins = data.balance.split('.').at(0);
                this.tokenText = this.add
                    .text(
                        width * 0.5 - 20,
                        height * 0.05,
                        (this.pendingHash ? '⚠ ' : '') + `Tokens: ${coins} `,
                        {
                            fontSize: 32,
                            fontFamily: 'Silkscreen',
                        }
                    )
                    .setOrigin(0.5);
                this.tokenIcon = this.add
                    .image(
                        this.tokenText.x + 145,
                        this.tokenText.y,
                        'tgs_token'
                    )
                    .setScale(0.1);
            }
        } catch (error) {
            console.error('Error fetching server data:', error);
            return;
        }
    }

    private async checkHash(): Promise<boolean> {
        try {
            const response = await fetch(
                `${environment.backendApiUrl}/transaction-status/${this.pendingHash}`
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            return (
                typeof data.status !== 'undefined' && data.status === 'Success'
            );
        } catch (error) {
            console.error('Error fetching server data:', error);
            return false;
        }
    }

    create() {
        const width = this.scale.width;
        const height = this.scale.height;

        if (!localStorage.getItem('highScore')) {
            localStorage.setItem('highScore', '0');
        }

        // background
        this.graphics = this.add.graphics({
            lineStyle: { color: 0x3abc12 },
            fillStyle: { color: 0x0000aa },
        });
        this.graphics.postFX.addTiltShift(0.2);
        this.graphics.postFX.addShine(0.5, 0.5, 4);
        this.graphics.postFX.addBlur(1, 0.025, 0, 10);

        this.baseWidth = width / 8;
        this.baseHeight = height / 8;
        this.seedRect = new Phaser.Geom.Rectangle(
            width / 2 - this.baseWidth / 2,
            height / 2 - (this.baseHeight / 2 + 50),
            this.baseWidth,
            this.baseHeight
        );
        this.input.on('pointerdown', () => {
            this.input.on('pointermove', (pointer: any) => {
                Phaser.Geom.Rectangle.CenterOn(
                    this.seedRect,
                    pointer.x,
                    pointer.y
                );
            });
        });

        this.add
            // @ts-ignore
            .rexBBCodeText(
                width * 0.5,
                height * 0.3,
                '[color=green]GREEN[color=white]&[color=red]RED',
                {
                    fontSize: 52,
                    fontFamily: 'Silkscreen',
                    backgroundColor: '#000000',
                }
            )
            .setOrigin(0.5);

        this.add
            .text(width * 0.35, height * 0.75, 'PLAY', {
                fontSize: 64,
                fontFamily: 'Silkscreen',
                backgroundColor: '#fff000',
                color: '#000000',
            })
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => {
                this.scene.restart({ key: 'Game' });
                this.scene.start('Game');
            });

        const urlParams = new URLSearchParams(window.location.search);
        this.address = urlParams.get('address') || '';
        this.pendingHash = localStorage.getItem('pending');

        // Display the token balance
        if (this.address.length > 2) {
            this.fetchBalance().then();
        }

        this.add
            .image(width * 0.75, height * 0.75, 'reload')
            .setOrigin(0.5)
            .setScale(0.27)
            .setInteractive()
            .on('pointerdown', () => {
                this.fetchBalance().then();
            });

        this.scoreText = this.add
            .text(width * 0.5, height * 0.9, `High Score: ${this.highScore}`, {
                fontSize: 32,
                fontFamily: 'Silkscreen',
            })
            .setOrigin(0.5);
    }

    override update() {
        this.highScore = +localStorage.getItem('highScore');
        this.scoreText.setText(`High Score: ${this.highScore}`);

        const clone = Phaser.Geom.Rectangle.Clone(this.seedRect);
        this.rectangles.push(clone);
        this.graphics.clear();

        for (let i = 0; i < this.rectangles.length; i++) {
            const rect = this.rectangles[i];

            Phaser.Geom.Rectangle.Inflate(
                rect,
                rect.width * 0.05,
                rect.height * 0.05
            );

            if (rect.width > this.scale.width + 200) {
                this.rectangles.splice(i--, 1);
            } else {
                this.graphics.lineStyle(1, 0xfff300);
                this.graphics.strokeRectShape(rect);
            }
        }
    }
}
